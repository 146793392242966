import TrackingManager from '@ingeniorforeningen/tracking';
import { PageMetadata } from '@ingeniorforeningen/umbraco-client';

class Tracker {
  private static instance: TrackingManager;

  private constructor(locale?: string) {
    // Initialize the TrackingManager instance
    Tracker.initialize(locale);
  }

  private static initialize(locale?: string): void {
    const useGtmProdContainer = process.env.NEXT_PUBLIC_USE_GTM_PROD_CONTAINER === 'true';
    const trackingLanguage = (locale || 'da') as TrackingManager['locale']; // Set the default tracking language
    Tracker.instance = new TrackingManager(
      useGtmProdContainer,
      'ida.dk',
      trackingLanguage,
    );
  }

  public static trackPageView = (metadata: PageMetadata) => {
    if (metadata) {
      Tracker.getInstance().page.view({
        pageTitle: metadata.header,
        nodeId: metadata.nodeId.toString(),
        section: 'ida konferencer',
      });
    }
  };

  public static trackError = (errorcode: number) => {
    Tracker.getInstance().page.error({ errorcode });
  };

  public static getInstance(locale?: string): TrackingManager {
    if (!Tracker.instance) {
      Tracker.initialize(locale);
    }
    return Tracker.instance;
  }
}

export default Tracker;
