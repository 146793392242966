import type { AppProps, NextWebVitalsMetric } from 'next/app'
import appInsights, { ApplicationInsights } from '../appInsights';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import Tracker from '../tracker';


export function reportWebVitals(metric: NextWebVitalsMetric) {
  appInsights.trackMetric({ name: metric.name, average: metric.value });
}

const App = ({ Component, pageProps }: AppProps) => {
  const { locale } = useRouter();
  Tracker.getInstance(locale);
  useEffect(() => {
    Tracker.trackPageView(pageProps?.data?.page?.metadata);
  }, [pageProps?.data?.page?.metadata]);

  return (
    <ApplicationInsights>
      <Component {...pageProps} />
    </ApplicationInsights>);
}

export default App;

